import { GenerativeModelState} from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {

    readGenerativeModels: (state: GenerativeModelState) => state.models,
    readGenerativeModel: (state: GenerativeModelState) => (modelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            return { ...filteredModels[0] };
        }
    },

    readGenerativeConnectedDatasets: (state: GenerativeModelState) => state.connectedDatasets,

   
};

const { read } = getStoreAccessors<GenerativeModelState, State>("");


export const readGenerativeModel = read(getters.readGenerativeModel);
export const readGenerativeModels = read(getters.readGenerativeModels);
export const readConnectedDatasets = read(getters.readGenerativeConnectedDatasets);
