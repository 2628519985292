const env = process.env.VUE_APP_ENV;

let envApiUrl = "";
let envCustApiUrl = "";
let envAuthUrl = "";
let envStripeKey = "";
let envUrlToPage = "";
let envSupersetUrl = "";

if (env === "production") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
  envCustApiUrl = `https://api.${process.env.VUE_APP_DOMAIN_PROD}`;
  envAuthUrl = `https://auth.${process.env.VUE_APP_DOMAIN_PROD}`;
  envStripeKey = process.env.VUE_APP_STRIPE_DEV !== undefined ? process.env.VUE_APP_STRIPE_DEV : "";
  envUrlToPage = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
} else if (env === "staging") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
  envCustApiUrl = `https://api.${process.env.VUE_APP_DOMAIN_STAG}`;
  envAuthUrl = `https://auth.${process.env.VUE_APP_DOMAIN_STAG}`;
  envStripeKey = process.env.VUE_APP_STRIPE_DEV !== undefined ? process.env.VUE_APP_STRIPE_DEV : "";
  envUrlToPage = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
} else if (env === "private-stag") {
  envSupersetUrl = `http://dash.${process.env.VUE_APP_DOMAIN_PRIVATE_STAG}`;
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_PRIVATE_STAG}`;
  envCustApiUrl = `http://api.${process.env.VUE_APP_DOMAIN_PRIVATE_STAG}`;
  envAuthUrl = `http://auth.${process.env.VUE_APP_DOMAIN_PRIVATE_STAG}`;
  envStripeKey = process.env.VUE_APP_STRIPE_DEV !== undefined ? process.env.VUE_APP_STRIPE_DEV : "";
  envUrlToPage = `http://${process.env.VUE_APP_DOMAIN_PRIVATE_STAG}`;
} else if (env === "cu-1") {
  envSupersetUrl = `http://${process.env.VUE_APP_DOMAIN_CU_1}`;
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_CU_1}`;
  envCustApiUrl = `http://${process.env.VUE_APP_DOMAIN_CU_1}`;
  envAuthUrl = `http://${process.env.VUE_APP_DOMAIN_CU_1}`;
  envStripeKey = process.env.VUE_APP_STRIPE_DEV !== undefined ? process.env.VUE_APP_STRIPE_DEV : "";
  envUrlToPage = `http://${process.env.VUE_APP_DOMAIN_CU_1}`;
} else {
  envSupersetUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}:8088`;
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}:8000`;
  envCustApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}:8000`;
  envAuthUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}:8000`;
  envStripeKey = process.env.VUE_APP_STRIPE_DEV !== undefined ? process.env.VUE_APP_STRIPE_DEV : "";
  envUrlToPage = "http://localhost:8000";
}

export const supersetUrl = envSupersetUrl;
export const customerApiUrl = envCustApiUrl;
export const authUrl = envAuthUrl;
export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME;
export const stripeKey: string = envStripeKey;
export const urlToPage: string = envUrlToPage;
