import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  IDatasetCreate,
  IDatasetUpdate,
  IDatasetUpdatePrompts,
  IDatasetUpdateRetention,
  IInferencePrompt,
  IInferencePromptCreate,
  IInferencePromptUpdate,
} from "@/interfaces";
import { State } from "../state";
import { DatasetState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetDatasets,
  commitSetDataset,
  commitDeleteDataset,
  commitDeleteInferencePrompt,
  commitSetInferencePrompts,
  commitSetInferencePrompt,
} from "./mutations";
import { AxiosError } from "axios";

import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";

type MainContext = ActionContext<DatasetState, State>;

export const actions = {
  /* ______________________

    Datasets
    ________________________
    */

  async actionGetDatasets(context: MainContext, payload: { id: number }) {
    try {
      const response = await api.getDatasets(context.rootState.main.token, payload.id);
      if (response) {
        commitSetDatasets(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateDataset(
    context: MainContext,
    payload: { workspaceId: number; dataset: IDatasetCreate; toggleHeader: boolean },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createDataset(
            context.rootState.main.token,
            payload.workspaceId,
            payload.dataset,
            payload.toggleHeader,
          ),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetDataset(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "Dataset successfully created", color: "success" });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteDataset(
    context: MainContext,
    payload: { workspaceId: number; datasetId: number },
  ) {
    try {
      const loadingNotification = { content: "deleting", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await Promise.all([
        api.deleteDataset(context.rootState.main.token, payload.workspaceId, payload.datasetId),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]);
      commitDeleteDataset(context, payload.datasetId);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "Dataset successfully removed", color: "success" });
    } catch (error) {
      console.log(error);
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateDataset(
    context: MainContext,
    payload: { workspaceId: number; datasetId: number; dataset: IDatasetUpdate },
  ) {
    // We can use IdatasetCreate
    try {
      await api
        .updateDataset(
          context.rootState.main.token,
          payload.workspaceId,
          payload.datasetId,
          payload.dataset,
        )
        .then((response) => {
          commitSetDataset(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionUpdateDatasetPrompts(
    context: MainContext,
    payload: { workspaceId: number; datasetId: number; datasetPrompts: IDatasetUpdatePrompts },
  ) {
    // We can use IdatasetCreate

    try {
      await api
        .updateDatasetPrompts(
          context.rootState.main.token,
          payload.workspaceId,
          payload.datasetId,
          payload.datasetPrompts,
        )
        .then((response) => {
          commitSetDataset(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionUpdateDatasetRetention(
    context: MainContext,
    payload: { workspaceId: number; datasetId: number; datasetRetention: IDatasetUpdateRetention },
  ) {
    // We can use IdatasetCreate
    try {
      await api
        .updateDatasetRetention(
          context.rootState.main.token,
          payload.workspaceId,
          payload.datasetId,
          payload.datasetRetention,
        )
        .then((response) => {
          commitSetDataset(context, response.data);
          commitAddNotification(context, {
            content: "Successfully updated dataset retention",
            color: "success",
          });
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionGetInferencePrompts(
    context: MainContext,
    payload: { workspaceId: number; datasetId: number },
  ) {
    try {
      const response = await api.getInferencePrompts(
        context.rootState.main.token,
        payload.workspaceId,
        payload.datasetId,
      );
      if (response) {
        commitSetInferencePrompts(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionCreateInferencePrompt(
    context: MainContext,
    payload: { workspaceId: number; datasetId: number; inferencePrompt: IInferencePromptCreate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await api
        .createInferencePrompt(
          context.rootState.main.token,
          payload.workspaceId,
          payload.datasetId,
          payload.inferencePrompt,
        )
        .then((response) => {
          commitSetInferencePrompt(context, response.data);
          commitRemoveNotification(context, loadingNotification);
          commitAddNotification(context, {
            content: "Inference Prompt successfully created",
            color: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionDeleteInferencePrompt(
    context: MainContext,
    payload: { workspaceId: number; datasetId: number; inferencePromptId: number },
  ) {
    try {
      const loadingNotification = { content: "deleting", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await Promise.all([
        api.deleteInferencePrompt(
          context.rootState.main.token,
          payload.workspaceId,
          payload.datasetId,
          payload.inferencePromptId,
        ),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]);
      commitDeleteInferencePrompt(context, payload.inferencePromptId);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "InferencePrompt successfully removed",
        color: "success",
      });
    } catch (error) {
      console.log(error);
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateInferencePrompt(
    context: MainContext,
    payload: {
      workspaceId: number;
      datasetId: number;
      inferencePromptId: number;
      inferencePrompt: IInferencePromptUpdate;
    },
  ) {
    // We can use IdatasetCreate
    try {
      await api
        .updateInferencePrompt(
          context.rootState.main.token,
          payload.workspaceId,
          payload.datasetId,
          payload.inferencePromptId,
          payload.inferencePrompt,
        )
        .then((response) => {
          commitSetInferencePrompt(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
};

const { dispatch } = getStoreAccessors<DatasetState, State>("");

export const dispatchCreateDataset = dispatch(actions.actionCreateDataset);
export const dispatchGetDatasets = dispatch(actions.actionGetDatasets);
export const dispatchUpdateDataset = dispatch(actions.actionUpdateDataset);
export const dispatchUpdateDatasetRetention = dispatch(actions.actionUpdateDatasetRetention);
export const dispatchUpdateDatasetPrompts = dispatch(actions.actionUpdateDatasetPrompts);
export const dispatchDeleteDataset = dispatch(actions.actionDeleteDataset);
export const dispatchCreateInferencePrompt = dispatch(actions.actionCreateInferencePrompt);
export const dispatchGetInferencePrompts = dispatch(actions.actionGetInferencePrompts);
export const dispatchDeleteInferencePrompt = dispatch(actions.actionDeleteInferencePrompt);
export const dispatchUpdateInferencePrompt = dispatch(actions.actionUpdateInferencePrompt);
