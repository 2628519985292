import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { modelModule } from './model';
import { datasetModule } from './dataset';
import { generativeModelModule } from './generative_model';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    model: modelModule,
    dataset: datasetModule,
    generativeModel: generativeModelModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
