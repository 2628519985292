import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        red: '#e80f00',
        pink: '#f20079',
        green: '#02b709',
      },
      red: '#e80f00',
      pink: '#f20079',
      green: '#02b709',
    },
    red: '#e80f00',
    pink: '#f20079',
    green: '#02b709',
  },
});
