import { IDataset, IInferencePrompt } from "@/interfaces";
import { DatasetState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  deletedataset(state: DatasetState, datasetId: number) {
    const datasets = state.datasets.filter((dataset: IDataset) => dataset.id !== datasetId);
    state.datasets = datasets;
  },
  setDatasets(state: DatasetState, payload: IDataset[]) {
    state.datasets = payload;
  },
  setDataset(state: DatasetState, payload: IDataset) {
    const datasets = state.datasets.filter((dataset: IDataset) => dataset.id !== payload.id);
    datasets.push(payload);
    state.datasets = datasets;
  },
  deleteInferencePrompt(state: DatasetState, inferencePromptId: number) {
    const inferencePrompts = state.inferencePrompts.filter(
      (inferencePrompt: IInferencePrompt) => inferencePrompt.id !== inferencePromptId,
    );
    state.inferencePrompts = inferencePrompts;
  },
  setInferencePrompts(state: DatasetState, payload: IInferencePrompt[]) {
    state.inferencePrompts = payload;
  },
  setInferencePrompt(state: DatasetState, payload: IInferencePrompt) {
    const inferencePrompts = state.inferencePrompts.filter(
      (inferencePrompt: IInferencePrompt) => inferencePrompt.id !== payload.id,
    );
    inferencePrompts.push(payload);
    state.inferencePrompts = inferencePrompts;
  },
};

const { commit } = getStoreAccessors<DatasetState, State>("");

export const commitSetDataset = commit(mutations.setDataset);
export const commitSetDatasets = commit(mutations.setDatasets);
export const commitDeleteDataset = commit(mutations.deletedataset);
export const commitSetInferencePrompts = commit(mutations.setInferencePrompts);
export const commitSetInferencePrompt = commit(mutations.setInferencePrompt);
export const commitDeleteInferencePrompt = commit(mutations.deleteInferencePrompt);
