import { DatasetState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  readDatasets: (state: DatasetState) => state.datasets,
  readDataset: (state: DatasetState) => (datasetId: number) => {
    const filteredDatasets = state.datasets.filter((dataset) => dataset.id === datasetId);
    if (filteredDatasets.length > 0) {
      return { ...filteredDatasets[0] };
    }
  },
  readDatasetByStoreId: (state: DatasetState) => (storeId: string) => {
    const filteredDatasets = state.datasets.filter((dataset) => dataset.store_id === storeId);
    if (filteredDatasets.length > 0) {
      return { ...filteredDatasets[0] };
    }
  },
  readInferencePrompts: (state: DatasetState) => state.inferencePrompts,
  readInferencePrompt: (state: DatasetState) => (inferencePromptId: number) => {
    const filteredInferencePrompts = state.inferencePrompts.filter(
      (inferencePrompt) => inferencePrompt.id === inferencePromptId,
    );
    if (filteredInferencePrompts.length > 0) {
      return { ...filteredInferencePrompts[0] };
    }
  },
};

const { read } = getStoreAccessors<DatasetState, State>("");

export const readDataset = read(getters.readDataset);
export const readDatasets = read(getters.readDatasets);
export const readDatasetByStoreId = read(getters.readDatasetByStoreId);
export const readInferencePrompts = read(getters.readInferencePrompts);
export const readInferencePrompt = read(getters.readInferencePrompt);
