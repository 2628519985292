import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { DatasetState } from "./state";
import { IInferencePrompt } from "@/interfaces";

const defaultState: DatasetState = {
  datasets: [],
  inferencePrompts: [],
};

export const datasetModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
