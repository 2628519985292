import {
  IMetrics,
  IGenerativeModel,
  IItem,
  IPredictions,
  IRecommendation,
  IDataset,
} from "@/interfaces";
import { GenerativeModelState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setGenerativeModels(state: GenerativeModelState, payload: IGenerativeModel[]) {
    state.models = payload;
  },
  setGenerativeModel(state: GenerativeModelState, payload: IGenerativeModel) {
    const models = state.models.filter((model: IGenerativeModel) => model.id !== payload.id);
    models.push(payload);
    state.models = models;
  },
  deleteGenerativeModel(state: GenerativeModelState, modelId: number) {
    console.log("THIS IS STATE", state);
    const models = state.models.filter((model: IGenerativeModel) => model.id !== modelId);
    state.models = models;
  },

  setConnectedDatasets(state: GenerativeModelState, payload: IDataset[]) {
    state.connectedDatasets = payload;
  },
};

const { commit } = getStoreAccessors<GenerativeModelState, State>("");

export const commitSetGenerativeModel = commit(mutations.setGenerativeModel);
export const commitSetGenerativeModels = commit(mutations.setGenerativeModels);
export const commitDeleteGenerativeModel = commit(mutations.deleteGenerativeModel);
export const commitSetConnectedDatasets = commit(mutations.setConnectedDatasets);
