import { ModelState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
    readModels: (state: ModelState) => state.models,
    readModel: (state: ModelState) => (modelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            return { ...filteredModels[0] };
        }
    },

    readFirstLabelContainer: (state: ModelState) => (modelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            if (filteredModels[0].label_containers.length > 0) {
                return { ...filteredModels[0].label_containers[0] };
            }
        }
    },
    readLabel: (state: ModelState) => (modelId: number, labelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            if (filteredModels[0].label_containers.length > 0) {
                if (filteredModels[0].label_containers[0].labels.length > 0) {
                    const filteredLabels = filteredModels[0].label_containers[0].labels.filter(
                        (label) => label.id === labelId,
                    );
                    if (filteredLabels.length > 0) {
                        return { ...filteredLabels[0] };
                    }
                }
            }
        }
    },

    readPredictionId: (state: ModelState) => (labelId: number) => {
        if (state.predictions.length > 0) {
            const filteredPredictions = state.predictions[0].predictions.filter(
                (prediction) => prediction.label === labelId,
            );
            if (filteredPredictions.length > 0) {
                return { ...filteredPredictions[0] };
            }
        }
    },

    readLabels: (state: ModelState) => (modelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            if (filteredModels[0].label_containers.length > 0) {
                if (filteredModels[0].label_containers[0].labels.length > 0) {
                    return [...filteredModels[0].label_containers[0].labels];
                }
            }
        }
        return [];
    },

    readAllLabelsByWorkspaceId: (state: ModelState) => (workspaceId: number) => {
        // Filter models by workspaceId
        const filteredModels = state.models.filter(
            (model) => model.workspace_id === workspaceId,
        );
    
        // Map each filtered model to its first label_container's labels, if it exists
        const allLabels = filteredModels.flatMap((model) =>
            model.label_containers.length > 0 && model.label_containers[0].labels.length > 0
                ? model.label_containers[0].labels
                : []
        );
    
        // Return the flattened array of all labels
        return allLabels;
    },

    readLabelCountsByIds: (state: ModelState) => (modelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            if (filteredModels[0].label_containers.length > 0) {
                if (filteredModels[0].label_containers[0].labels.length > 0) {
                    let labelCounts: any = {};
                    filteredModels[0].label_containers[0].labels.forEach((l) => {
                        labelCounts = { ...labelCounts, ...{[l.id]: l.counter} };
                    });
                    return { ...labelCounts};
                }
            }
        }
        return {};
    },

    readLabelColorsByIds: (state: ModelState) => (modelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            if (filteredModels[0].label_containers.length > 0) {
                if (filteredModels[0].label_containers[0].labels.length > 0) {
                    let labelCounts: any = {};
                    filteredModels[0].label_containers[0].labels.forEach((l) => {
                        labelCounts = { ...labelCounts, ...{[l.id]: l.color} };
                    });
                    return { ...labelCounts};
                }
            }
        }
    },

    readLabelsByIds: (state: ModelState) => (modelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            if (filteredModels[0].label_containers.length > 0) {
                if (filteredModels[0].label_containers[0].labels.length > 0) {
                    let labelCounts: any = {};
                    filteredModels[0].label_containers[0].labels.forEach((l) => {
                        labelCounts = { ...labelCounts, ...{[l.id]: l} };
                    });
                    return { ...labelCounts};
                }
            }
        }
    },

    readLabelsByName: (state: ModelState) => (modelId: number) => {
        const filteredModels = state.models.filter(
            (model) => model.id === modelId,
        );
        if (filteredModels.length > 0) {
            if (filteredModels[0].label_containers.length > 0) {
                if (filteredModels[0].label_containers[0].labels.length > 0) {
                    let labelCounts: any = {};
                    filteredModels[0].label_containers[0].labels.forEach((l) => {
                        labelCounts = { ...labelCounts, ...{[l.name]: l} };
                    });
                    return { ...labelCounts};
                }
            }
        }
    },

    readTotalPredictions: (state: ModelState) => {
        let total: number = 0;
        if (state.predictions.length > 0) {
            state.predictions[0].predictions.forEach((p) => {
                total += p.total_predictions;
            });
        }
        return total;
    },

    readPredictionsByIds: (state: ModelState) => {
        if (state.predictions.length > 0) {
            if (state.predictions[0].predictions.length > 0) {
                let predictions: any = {};
                state.predictions[0].predictions.forEach((p) => {
                    predictions = { ...predictions, ...{[p.label]: p.total_predictions} };
                });
                return { ...predictions};
            }
        }
        return {};
    },

    readPrediction: (state: ModelState) => {
        if (state.predictions.length > 0) {
            return state.predictions[0];
        }
    },

    readPredictions: (state: ModelState) => state.predictions,
    readAccuracy: (state: ModelState) => state.accuracy,

    readConnectedDatasets: (state: ModelState) => state.connectedDatasets,

    readRecommendation: (state: ModelState) => state.recommendation,
    readRecommendationType: (state: ModelState) => state.recommendation_type,
    readTarget: (state: ModelState) => state.target,

    readItems: (state: ModelState) => state.items,
    readItem: (state: ModelState) => (itemId: string) => {
        const filteredItems = state.items.filter((item) => item.id === itemId);
        if (filteredItems.length > 0) {
            return { ...filteredItems[0] };
        }
    },
    readMetrics: (state: ModelState) => state.metrics,
    readPreviewItems: (state: ModelState) => state.previewItems,
    readPreviewHeader: (state: ModelState) => state.previewHeader,
};

const { read } = getStoreAccessors<ModelState, State>("");

export const readModel = read(getters.readModel);
export const readModels = read(getters.readModels);


export const readAccuracy = read(getters.readAccuracy);

export const readItem = read(getters.readItem);
export const readItems = read(getters.readItems);

export const readPreviewItems = read(getters.readPreviewItems);
export const readPreviewHeader = read(getters.readPreviewHeader);

export const readRecommendation = read(getters.readRecommendation);
export const readRecommendationType = read(getters.readRecommendationType);
export const readTarget = read(getters.readTarget);

export const readMetrics = read(getters.readMetrics);

export const readFirstLabelContainer = read(getters.readFirstLabelContainer);

export const readConnectedDatasets = read(getters.readConnectedDatasets);

export const readLabelCountsByIds = read(getters.readLabelCountsByIds);
export const readLabels = read(getters.readLabels);
export const readAllLabelsByWorkspaceId = read(getters.readAllLabelsByWorkspaceId);
export const readLabel = read(getters.readLabel);
export const readLabelColorsByIds = read(getters.readLabelColorsByIds);
export const readLabelsByIds = read(getters.readLabelsByIds);
export const readLabelsByName = read(getters.readLabelsByName);


export const readPredictions = read(getters.readPredictions);
export const readPrediction = read(getters.readPrediction);
export const readTotalPredictions = read(getters.readTotalPredictions);
export const readPredictionId = read(getters.readPredictionId);
export const readPredictionsByIds = read(getters.readPredictionsByIds);
