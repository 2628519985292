import { IMetrics, IModel, IItem, IPredictions, IRecommendation, IDataset } from '@/interfaces';
import { ModelState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setModels(state: ModelState, payload: IModel[]) {
        state.models = payload;
    },
    setModel(state: ModelState, payload: IModel) {
        const models = state.models.filter((model: IModel) => model.id !== payload.id);
        models.push(payload);
        state.models = models;
    },
    deletemodel(state: ModelState, modelId: number) {
        const models = state.models.filter((model: IModel) => model.id !== modelId);
        state.models = models;
    },
    setItems(state: ModelState, payload: IItem[]) {
        state.items = payload;
    },
    setPreviewItems(state: ModelState, payload: IItem[]) {
        state.previewItems = payload;
    },
    setPreviewHeader(state: ModelState, payload: string) {
        state.previewHeader = payload;
    },
    setRecommendation(state: ModelState, payload: IRecommendation) {
        state.items = payload.items;
        state.recommendation_type = payload.recommendation_type;
        state.recommendation = payload.recommendation;
        state.target = payload.target;
    },
    setItem(state: ModelState, payload: IItem) {
        const items = state.items.filter((item: IItem) => item.id !== payload.id);
        items.push(payload);
        state.items = items;
    },
    setPredictions(state: ModelState, payload: IPredictions[]) {
        state.predictions = payload;
    },
    setConnectedDatasets(state: ModelState, payload: IDataset[]) {
        state.connectedDatasets = payload;
    },
    setAccuracy(state: ModelState, payload: number) {
        state.accuracy = payload;
    },

    setMetrics(state: ModelState, payload: IMetrics) {
        state.metrics = payload;
    },
};

const { commit } = getStoreAccessors<ModelState, State>('');

export const commitSetModel = commit(mutations.setModel);
export const commitSetModels = commit(mutations.setModels);
export const commitDeletemodel = commit(mutations.deletemodel);

export const commitSetItem = commit(mutations.setItem);
export const commitSetItems = commit(mutations.setItems);

export const commitSetPreviewItems = commit(mutations.setPreviewItems);
export const commitSetPreviewHeader = commit(mutations.setPreviewHeader);

export const commitSetPredictions = commit(mutations.setPredictions);
export const commitSetAccuracy = commit(mutations.setAccuracy);

export const commitSetConnectedDatasets = commit(mutations.setConnectedDatasets);

export const commitSetRecommendation = commit(mutations.setRecommendation);

export const commitSetMetrics = commit(mutations.setMetrics);
