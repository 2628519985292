import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { GenerativeModelState } from "./state";


const defaultGenerativeModelState: GenerativeModelState = {
  models: [],
  connectedDatasets: [],
};


export const generativeModelModule = {
  state: defaultGenerativeModelState,
  mutations,
  actions,
  getters,
};
