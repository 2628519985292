import { api } from "@/api";
import { ActionContext } from "vuex";
import { IModelCreate, IGenerativeModelCreate } from "@/interfaces";
import { State } from "../state";
import { GenerativeModelState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetGenerativeModels,
  commitSetGenerativeModel,
  commitDeleteGenerativeModel,
  commitSetConnectedDatasets,
} from "./mutations";

import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";
import { AxiosError } from "axios";

type MainContext = ActionContext<GenerativeModelState, State>;

export const actions = {
  /* ______________________

    Models
    ________________________
    */

  async actionGenerativeGetModels(context: MainContext, payload: { id: number }) {
    try {
      const response = await api.getGenerativeModels(context.rootState.main.token, payload.id);
      if (response) {
        commitSetGenerativeModels(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionGenerativeCreateModel(
    context: MainContext,
    payload: {
      workspaceId: number;
      model: IModelCreate;
      datasetId: number;
      modelFilters: any[] | boolean;
    },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createModelFromUpload(
            context.rootState.main.token,
            payload.workspaceId,
            payload.datasetId,
            payload.modelFilters,
            payload.model,
          ),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetGenerativeModel(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "Model successfully created", color: "success" });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionGenerativeDeleteModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    try {
      console.log("I am here");
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await Promise.all([
        api.deleteGenerativeModel(
          context.rootState.main.token,
          payload.workspaceId,
          payload.modelId,
        ),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]);
      commitDeleteGenerativeModel(context, payload.modelId);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "Model successfully removed", color: "success" });
    } catch (error) {
      commitAddNotification(context, {
        content: "Model couldn't be removed due to access restrictions",
        color: "error",
      });
      console.log(error);
      // await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionGenerativeUpdateGenerativeModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number; model: IGenerativeModelCreate },
  ) {
    // We can use IModelCreate
    try {
      await api
        .updateGenerativeModel(
          context.rootState.main.token,
          payload.workspaceId,
          payload.modelId,
          payload.model,
        )
        .then((response) => {
          commitSetGenerativeModel(context, response.data);
          commitAddNotification(context, {
            content: "Successfully updated model",
            color: "success",
          });
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionGenerativeRetrainModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    // We can use IModelCreate
    try {
      await api
        .retrain(context.rootState.main.token, payload.workspaceId, payload.modelId)
        .then((response) => {
          commitSetGenerativeModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionGenerativeShareModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    try {
      await api
        .shareModel(context.rootState.main.token, payload.workspaceId, payload.modelId)
        .then((response) => {
          commitSetGenerativeModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
  async actionGenerativeUnshareModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    try {
      await api
        .unshareModel(context.rootState.main.token, payload.workspaceId, payload.modelId)
        .then((response) => {
          commitSetGenerativeModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionGenerativeResetModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.resetModel(context.rootState.main.token, payload.workspaceId, payload.modelId),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetGenerativeModel(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "Model successfully reset", color: "success" });
    } catch (error) {
      console.log(error);
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionGenerativeGetGenerativeConnectedDatasets(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    try {
      const response = await api.getGenerativeModelConnectedDatasets(
        context.rootState.main.token,
        payload.workspaceId,
        payload.modelId,
      );
      if (response) {
        commitSetConnectedDatasets(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<GenerativeModelState, State>("");

export const dispatchCreateGenerativeModel = dispatch(actions.actionGenerativeCreateModel);
export const dispatchGetGenerativeModels = dispatch(actions.actionGenerativeGetModels);
// export const dispatchUpdateModel = dispatch(actions.actionGenerativeUpdateModel);
export const dispatchDeleteGenerativeModel = dispatch(actions.actionGenerativeDeleteModel);
export const dispatchResetGenerativeModel = dispatch(actions.actionGenerativeResetModel);
export const dispatchUpdateGenerativeModel = dispatch(
  actions.actionGenerativeUpdateGenerativeModel,
);
export const dispatchRetrainGenerativeModel = dispatch(actions.actionGenerativeRetrainModel);
export const dispatchShareGenerativeModel = dispatch(actions.actionGenerativeShareModel);
export const dispatchUnshareGenerativeModel = dispatch(actions.actionGenerativeUnshareModel);
export const dispatchGetGenerativeConnectedDatasets = dispatch(
  actions.actionGenerativeGetGenerativeConnectedDatasets,
);
