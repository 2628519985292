import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ModelState } from "./state";

const defaultState: ModelState = {
  models: [],
  items: [],
  previewItems: [],
  previewHeader: "",
  predictions: [],
  accuracy: 0,
  recommendation_type: "",
  recommendation: "",
  target: {
    label: -1,
    label_container: -1,
  },
  metrics: null,
  connectedDatasets: [],
};

export const modelModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
